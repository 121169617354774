<template>
  <div>
    <div class="d-flex bg-white text-dark p-2 mb-3">
      <TInputCheckbox
        :checked="withoutOrder"
        @change="changeScope('withoutOrder', $event)"
        :disabled="loading"
        label="No order"
      />
      <TInputCheckbox
        class="ml-3"
        :checked="bidding"
        @change="changeScope('bidding', $event)"
        :disabled="loading"
        label="Bidding"
      />
    </div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="order.order_auction_logs"
      @click-clear-filter="clearFilter"
      resource="/management"
      creatable
      @click-create="showCreateModal = true"
    >
      <template #append-actions="{ item }">
        <CCol class="d-flex px-2" col="12">
          <TButtonEdit @click="editItem(item)" />
          <TButtonDelete @click="removeLog(item.id)" />
        </CCol>
      </template>
      <template #price="{ item }">
        <td>
          <TMessageMoney :amount="item.price" currency="JPY" />
        </td>
      </template>
      <template #link="{ item }">
        <td class="text-break">
          <TLink v-if="item.link" :href="item.link" :content="item.link" />
          <TMessage v-else :content="item.link" noTranslate />
        </td>
      </template>
      <template #order_id="{ item }">
        <td>
          <TMessage :content="item.order_id" noTranslate bold />
        </td>
      </template>

      <template #status="{ item }">
        <td>
          <TMessage
            :content="item.status == 'success' ? 'Success' : item.status"
            bold
          />
        </td>
      </template>
      <template #data="{ item }">
        <td class="text-break">
          {{ item.data }}
        </td>
      </template>
      <template #user_id="{ item }">
        <td>
          <TMessage
            :content="item.user ? item.user.name : item.user_id"
            noTranslate
            bold
          />
        </td>
      </template>
      <template #date_bid="{ item }">
        <td>
          <TMessageDateTime :content="item.date_bid" />
        </td>
      </template>

      <template #order_id-filter>
        <TInputText
          placeholder="Order Id"
          :value.sync="filter.order_id"
          @update:value="filterChange"
        />
      </template>
      <template #user_id-filter>
        <SSelectCustomer
          :value.sync="filter.user_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #id-filter>
        <TInputText
          placeholder="Id"
          :value.sync="filter.id"
          @update:value="filterChange"
        />
      </template>
      <template #link-filter>
        <TInputText
          placeholder="Id"
          :value.sync="filter.link"
          @update:value="filterChange"
        />
      </template>
      <template #date_bid-filter>
        <TInputDateTimeRange
          :value.sync="bidDateRange"
          @update:value="
            filter_between['date_bid'] = `${$event.start},${$event.end}`
          "
          @change="filterChange"
        />
      </template>
    </TTableAdvance>

    <AuctionLogModal @updated="fetchLogs()" :show.sync="showCreateModal" />
    <AuctionLogModal
      @updated="fetchLogs()"
      :show.sync="showEditModal"
      :log="detail"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AuctionLogModal from "../../components/AuctionLogModal.vue";

export default {
  components: { AuctionLogModal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px; min-width: 40px" },
        {
          key: "id",
          label: "Id",
          _style: "width: 80px; min-width: 80px",
        },
        {
          key: "status",
          label: "Status",
          _classes: "",
          _style: "width: 130px; min-width: 130px",
        },
        {
          key: "type",
          label: "Type",
          _classes: "",
          _style: "width: 130px; min-width: 130px",
        },
        {
          key: "link",
          label: "Link",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "order_id",
          label: "Sale order",
          _classes: "",
          _style: "width: 130px; min-width: 130px",
        },
        {
          key: "user_id",
          label: "Customer",
          _classes: "",
          _style: "width: 170px; min-width: 170px",
        },
        {
          key: "price",
          label: "Price",
          _classes: "",
          _style: "width: 170px; min-width: 170px",
        },

        {
          key: "data",
          label: "Info",
          _classes: "",
          _style: "width: 350px; min-width: 350px",
        },
        {
          key: "date_bid",
          label: "Created at",
          _style: "width: 220px; min-width: 220px",
        },
      ],
      showCreateModal: false,
      showEditModal: false,
      filter: {},
      filter_between: {},
      bidDateRange: {
        start: null,
        end: null,
      },
      withoutOrder: false,
      bidding: false,
    };
  },
  created() {
    this.$store.dispatch("order.order_auction_logs.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      list: "order.order_auction_logs.list",
      detail: "order.order_auction_logs.detail",
      loading: "order.order_auction_logs.loading",
    }),
  },
  methods: {
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filter,
        ...filterBetween,
      });
      this.$store.dispatch(
        "order.order_auction_logs.apply-query",
        filterFields
      );
    },
    clearFilter() {
      this.bidDateRange = {
        start: null,
        end: null,
      };
      this.filter = {};
      this.filterChange();
    },
    changeScope(scope, value) {
      this[scope] = value;
      this.filter[scope] = value;
      this.filterChange();
    },
    removeLog(id) {
      this.$store.dispatch("order.order_auction_logs.delete", id);
    },
    fetchLogs() {
      this.$store.dispatch("order.order_auction_logs.apply-query", {});
    },
    editItem(item) {
      this.$store.commit("order.order_auction_logs.select", item.id);
      this.showEditModal = true;
    },
  },
};
</script>
