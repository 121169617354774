<template>
  <TModal
    :title="is_edit ? 'Edit' : 'Create'"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
    :creating="loading"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: loading }"
        variant="outline"
        @click="create"
        v-if="!is_edit"
      />
      <TButtonSave
        variant="outline"
        @click="update"
        v-if="is_edit"
        :options="{ disabled: loading }"
      />
    </template>
    <SSelectCustomer
      label="Customer"
      class="mb-3"
      :value.sync="input.user_id"
      noCustomLabel
    />
    <TInputText
      label="Link"
      class="mb-3"
      @update:value="input.link = $event"
      :value="input.link"
    />
    <TInputNumber
      label="Price"
      class="mb-3"
      @update:value="input.price = $event"
      :value="input.price"
    />
    <CRow>
      <CCol col="12" md="6">
        <TInputText
          label="Type"
          class="mb-3"
          @update:value="input.type = $event"
          :value="input.type"
        />
      </CCol>
      <CCol col="12" md="6">
        <TInputText
          label="Status"
          class="mb-3"
          @update:value="input.status = $event"
          :value="input.status"
        />
      </CCol>
    </CRow>
    <TInputText
      label="Token"
      class="mb-3"
      @update:value="input.token_device = $event"
      :value="input.token_device"
    />
    <TInputTextarea
      label="Info"
      class="mb-3"
      :value="input.data"
      @update:value="input.data = $event"
    />
    <CRow>
      <CCol col="12" md="6">
        <TInputDateTime
          label="Start time"
          class="mb-3"
          :value="input.date_bid"
          @update:value="input.date_bid = $event"
        />
      </CCol>
      <CCol col="12" md="6">
        <TInputDateTime
          label="End time"
          class="mb-3"
          :value="input.date_finish"
          @update:value="input.date_finish = $event"
        />
      </CCol>
    </CRow>
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    log: {
      type: Object,
      require: false,
    },
  },
  inputDefault: {
    link: "",
    name: "",
    price: 0,
    type: "",
    status: "",
    token_device: "",
    date_bid: 0,
    date_finish: 0,
  },
  data() {
    return {
      options: [
        { value: "user", label: "Customer" },
        { value: "supplier", label: "Supplier" },
      ],
      loading: false,
      data: this.getInput(),
      disabled: false,
    };
  },
  watch: {
    log() {
      this.data = this.getInput();
    },
  },
  computed: {
    is_edit() {
      if (!this.log) {
        return false;
      }
      return !!this.log.id;
    },
    input: {
      get: function () {
        return this.data;
      },
      set: function (newValue) {
        return (this.data = newValue);
      },
    },
  },
  methods: {
    create() {
      this.loading = true;
      let data = {
        ...this.input,
      };
      this.$store
        .dispatch("order.order_auction_logs.create", this.lodash.pickBy(data))
        .then((data) => {
          this.input = this.inputDefault();
          this.$emit("update:show", false);
          this.$emit("updated", data);
          this.reset();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      let data = {
        ...this.input,
      };
      this.$store
        .dispatch("order.order_auction_logs.detail.update", {
          ...this.lodash.pickBy(data),
        })
        .then((data) => {
          this.$emit("update:show", false);
          this.$emit("updated", data);
        });
    },
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    inputDefault() {
      return { ...this.$options.inputDefault };
    },
    getInput() {
      if (!this.is_edit) {
        return this.inputDefault();
      }
      return { ...this.log };
    },
  },
};
</script>
